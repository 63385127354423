import React from "react"
import { css } from "@emotion/core"
import {
  dataWithDash,
  sliceTimeWithColon,
  numberWithCommas,
} from "../../utils/numberFormat"

import { CreditCardPaymentProps } from "../../containers/receipt"

const CreditCardPayment = ({
  minusText,
  isCancel,
  cardNumber,
  tradeDate,
  tradeTime,
  subTotal,
}: CreditCardPaymentProps) => {
  return (
    <>
      <div css={wrap}>
        <p>[결제방법]</p>
        <p>카드결제</p>
      </div>
      <div css={wrap}>
        <p>[카드번호]</p>
        <p>{cardNumber}</p>
      </div>
      <div css={wrap}>
        <p>{isCancel ? "[승인취소금액]" : "[승인금액]"}</p>
        <p>
          {minusText} {numberWithCommas(subTotal)} 원
        </p>
      </div>
      <div css={wrap}>
        <p>[승인일시]</p>
        <p>
          {tradeDate} {tradeTime}
        </p>
      </div>
    </>
  )
}

export default CreditCardPayment

const text__center = css`
  text-align: center;
`
const wrap = css`
  display: flex;
  justify-content: space-between;
`
