import {
  dateWithSlash,
  dataWithDash,
  timeWithColon,
  sliceTimeWithColon,
} from "../../utils/numberFormat"

export const chargeReader000 = (api: any) => {
  const paymentResult = {
    cardNum: api.payment.result.cardNumber,
    vatAmount: Number(api.payment.result.tax),
    tradeDate: dataWithDash(api.payment.result.date),
    tradeTime: sliceTimeWithColon(api.payment.result.time),
  }

  const phoneNum = api.wallet.ownerId
  const membersNum = phoneNum.slice(-4)

  const charge = api.charge

  // 기타 전처리
  const issueReceiptTimeStamp = api.timeStamp._seconds * 1000
  const issueReceiptTime = new Date(issueReceiptTimeStamp)
  const approvalDate = dateWithSlash(issueReceiptTime)
  const approvalTime = timeWithColon(issueReceiptTime)

  const result = {
    approvalDate,
    approvalTime,
    membersNum,
    charge,
    paymentResult,
  }
  return result
}
