import { number, string } from "prop-types"

export const numberWithCommas = (x: number | string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

/**
 *
 * @param date
 * date객체로 들어오는 날짜를 년,월,일로 구분하기 위한 함수입니다.
 * 20121212  -> 2012/12/12
 */
export const dateWithSlash = (date: any) => {
  return `${date.getFullYear()}/${("0" + (date.getMonth() + 1)).slice(-2)}/${(
    "0" + date.getUTCDate()
  ).slice(-2)}`
}

export const dataWithDash = (date: string) => {
  return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`
}

/**
 *
 * @param time
 *
 */
export const timeWithColon = (time: any) => {
  return `${("0" + time.getHours()).slice(-2)}:${(
    "0" + time.getMinutes()
  ).slice(-2)}:${("0" + time.getSeconds()).slice(-2)}`
}

export const sliceTimeWithColon = (time: string) => {
  return `${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(4, 6)}`
}
