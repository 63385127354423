import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import axios from "axios"
import { numberWithCommas } from "../utils/numberFormat"
import * as queryString from "query-string"
import { chargeReader000 } from "./chargeReaders/chargeReader000"

import CreditCardPayment from "../components/receipt/CreditCardPayment"

const WalletReceipt = ({ location }: any) => {
  const { walletId, chargeId } = queryString.parse(location.search)
  const [api, setApi] = useState<any>({})
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    axios
      .post(
        "https://us-central1-planz-6761f.cloudfunctions.net/walletReceipt",
        null,
        { params: { walletId, chargeId } }
      )
      .then(res => setApi(res.data))
      .catch(() => setIsError(true))
  }, [])

  if (Object.keys(api).length === 0) {
    if (isError) {
      return (
        <div css={align__center}>
          <p css={receipt__error}>
            Page not found. <br />
            <br />
            해당 거래 내역이 없습니다.
          </p>
        </div>
      )
    }
    return (
      <div css={align__center}>
        <p css={receipt__error}>로딩중입니다. ☕︎</p>
      </div>
    )
  }

  // versioning code
  const dataVersions = [{ version: "1.2.1", reader: chargeReader000 }]
  const foundReader = dataVersions[0]

  const readResult = foundReader.reader(api)
  const paymentResult = readResult.paymentResult

  return (
    <div css={receipt__bg}>
      <div css={align__center}>
        <section css={container}>
          <CompanyInfo
            approvalDate={readResult.approvalDate}
            approvalTime={readResult.approvalTime}
          />
          <ItemList charge={readResult.charge} />
          <article css={paymentInfo__container}>
            <SubTotal
              subTotal={readResult.charge.totalPrice}
              vatAmount={paymentResult.vatAmount}
            />

            <CreditCardPayment
              minusText={""}
              isCancel={false}
              cardNumber={paymentResult.cardNum}
              tradeDate={paymentResult.tradeDate}
              tradeTime={paymentResult.tradeTime}
              subTotal={readResult.charge.totalPrice}
            />
          </article>
        </section>
      </div>
    </div>
  )
}

interface CompanyInfoProps {
  approvalDate: string
  approvalTime: string
}

export interface PaycoPaymentProps {
  isCancel: boolean
  approvalResultList: any
  tradeDate: string
  tradeTime: string
  minusText: string
}

export interface CreditCardPaymentProps {
  minusText: string
  isCancel: boolean
  tradeDate: string
  tradeTime: string
  cardNumber: string
  subTotal: string
}

const CompanyInfo = ({ approvalDate, approvalTime }: CompanyInfoProps) => {
  return (
    <>
      <div css={title}>PLANZ COFFEE</div>
      <div css={branInfo__wrapper}>
        <div css={wrap}>
          <span>플랜즈 커피</span>
          <span>T: 02-6956-0232</span>
        </div>
        <span>경기도 안양시 만안구 전파로24번길 62 삼부스퀘어 3층</span>
        <span></span>
        <div css={wrap}>
          <span>대표: 최준혁</span>
          <span>523-86-00977</span>
        </div>
        <div css={wrap}>
          <span>
            {approvalDate} {approvalTime}
          </span>
        </div>
      </div>
    </>
  )
}

interface itemListProps {
  charge: any
}

const ItemList = ({ charge }: itemListProps) => {
  return (
    <table css={item__list__table}>
      <thead css={list__thead}>
        <tr>
          <th>상품명</th>
          <th>단가</th>
          <th>수량</th>
          <th>금액</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{`플랜즈 포인트 ${charge.totalPoint} 충전`}</td>
          <td css={text__center}>{numberWithCommas(charge.totalPrice)}</td>
          <td css={text__center}>{1}</td>
          <td css={text__center}>{numberWithCommas(charge.totalPrice)}</td>
        </tr>
      </tbody>
      <tfoot css={list__tfoot}>
        <tr>
          <td colSpan={2}>합계</td>
          <td></td>
          <td css={text__center}>{numberWithCommas(charge.totalPrice)}</td>
        </tr>
      </tfoot>
    </table>
  )
}

interface SubTotalProps {
  subTotal: number
  vatAmount: number
}

const SubTotal = ({ subTotal, vatAmount }: SubTotalProps) => {
  return (
    <div css={container__line}>
      <div css={subTotal__text}>
        <span>결제금액</span>
        <span>{numberWithCommas(subTotal)} 원</span>
      </div>
      <div css={text__margin}>
        <span>(VAT 포함)</span>
        <span>({numberWithCommas(vatAmount)})</span>
      </div>
    </div>
  )
}

interface MembershipProps {
  membersNum: number
  couponAmount: number
  sticker: number
  isCouponUsed: boolean
}

const Membership = ({
  membersNum,
  couponAmount,
  sticker,
  isCouponUsed,
}: MembershipProps) => {
  return (
    <>
      <article css={members__container}>
        <div>
          ☕︎ <span>{membersNum}님</span> 멤버십 현황
        </div>
        <div>* 쿠폰: {!isCouponUsed ? couponAmount : couponAmount - 1}개</div>
        <div>* 스티커: {sticker}개</div>
      </article>
    </>
  )
}

const receipt__bg = css`
  width: 100%;
  height: 100vh;
  background-color: rgba(49, 40, 33, 0.164);
  z-index: -1;
  font-family: "Spoqa Han Sans";
`

const container = css`
  margin: 5vh 0;
  padding: 2vh 5vw;
  border-radius: 6px;
  background-color: white;
  box-shadow: 7px 7px 15px 3px rgb(177, 177, 177);
  width: 16rem;
  min-width: 15rem;
  font-size: 10px;
  line-height: 150%;

  @media (min-width: 768px) {
    width: 23rem;
    padding: 2vh 3vw;
    font-size: 15px;
  }
`

const receipt__error = css`
  margin: 5vh 0;
  padding: 2vh 5vw;
  border-radius: 6px;
  background-color: white;
  box-shadow: 7px 7px 15px 3px rgb(177, 177, 177);
  line-height: 150%;
  text-align: center;
  font-size: 0.7rem;
  font-family: "Spoqa Han Sans";
  font-weight: normal;

  @media (min-width: 768px) {
    width: 32rem;
    padding: 2vh 3vw;
    font-size: 1.5rem;
  }
`

const align__center = css`
  display: flex;
  justify-content: center;
`

const title = css`
  padding: 1.8vh 0;
  text-align: center;
  border-bottom: 1.5px solid black;
  font-size: 1.1rem;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`

const branInfo__wrapper = css`
  display: flex;
  flex-direction: column;
  padding: 0.6vh 0;
  font-weight: normal;
`

const wrap = css`
  display: flex;
  justify-content: space-between;
`

const item__list__table = css`
  width: 100%;
  border-top: 3px double black;
`

const list__thead = css`
  border-bottom: 1px dashed grey;
  padding: 3vh 0.5rem;
  font-size: 9px;
  line-height: 130%;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`

const list__tfoot = css`
  margin: 5px 0;
  font-weight: bold;
  font-size: 11px;

  @media (min-width: 768px) {
    font-size: 15px;
  }
`

const paymentInfo__container = css`
  margin: 5px 0;
  padding-bottom: 5px;
  border-bottom: 1px dashed black;
`

const subTotal__text = css`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px dashed black;
  font-weight: 600;
  font-size: 15px;

  @media (min-width: 768px) {
    font-size: 21px;
    margin-top: 10px;
    padding-top: 10px;
  }
`

const container__line = css`
  border-bottom: 1px dashed black;
`

const members__container = css`
  padding: 5px 8px;
  span {
    font-weight: 600;
  }
`

const text__margin = css`
  display: flex;
  justify-content: space-between;
  margin: 2px 15px 5px 5px;
`

const text__center = css`
  text-align: center;
`

export default WalletReceipt
